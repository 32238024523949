import React from "react";
import Layout from "../components/layout";
import UnoSection from "../components/uno-section";
import InGrid from "../components/in-grid";

import CenteredTextComponent from "../components/centered-text";

import problemIntroImage from "../images/footer-pages/purpose-intro-d.jpg";

const PurposePage = () => (
  <Layout currentPage="problem">
    <UnoSection
      backgroundImage={problemIntroImage}
      minHeight="80vh"
      bottomPadding
      verticalCenter
    >
      <div className="thispagetitle">Our Purpose</div>
      <CenteredTextComponent title="We’re on a mission to save the planet from waste." />
    </UnoSection>
    <div className="container" style={{paddingLeft: "0", paddingBottom: "100px", paddingTop: "60px"}}>
     <div className="col-md-12">
      <InGrid columns={9}>
        <div className="aligned-left">
          <p>
          Climate change is by far the greatest threat to our generation’s livelihood. While hydration is one of the most fundamental human needs, we don't have a sustainable beverage container integrated into our supply chain. We need a scalable solution that can be implemented quickly if we want an inhabitable planet. 
Most people are so discouraged by this, that they’ve just given up. We are not those people. We believe big change is necessary and very much possible.

          </p>
          <p>
          Our vision is simple. We should all have one incredible cup of our own that can be easily filled anywhere with all the beverages we love. A cup that would be perfect for any type of drink we want throughout the day. A cup that we can use at home, our favorite coffee shop, go-to lunch spots, office, gym and more. A cup that makes doing the right thing easier than ever before.

          </p>
          <p>
          At Uno, we’re creating an end-to-end solution to address the biggest pain points that are currently prohibiting consumers, workplaces and merchants from sustainable beverage consumption. We’re not just making a new type of reusable cup—we’re designing the infrastructure and a business model to power it. 
          </p>
          <p>
          One cup for everything is a simple idea, but realizing it is no simple task. We are bringing together expertise in design, technology, engineering, business, operations and communications to create the best way to buy and drink beverages. We are a business driven by the belief that what’s good for people and the planet is the best investment. 
          </p>
        </div>
      </InGrid>
      </div>
    </div>
  </Layout>
);

export default PurposePage;
